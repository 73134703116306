<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    :max-width="900"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal(false)"
  >
    <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            id="btn-close"
            name="btn-close"
            @click="closeModal(false)"
          >
            <v-icon>fa-solid fa-xmark</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="b-dialog--content">
          <v-row dense>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <v-text-field
                id="name"
                name="name"
                label="Beneficiário"
                dense
                outlined
                required
                :rules="[required]"
                v-model="model.name"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <v-text-field
                id="document"
                name="document"
                label="CPF"
                dense
                outlined
                v-mask="'###.###.###-##'"
                v-model="model.document"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <v-text-field
                id="kinship"
                name="kinship"
                label="Parentesco"
                dense
                outlined
                required
                :rules="[required]"
                v-model="model.kinship"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6">
              <BaseAutonumeric
                id="percentage"
                name="percentage"
                label="Percentual"
                suffix="%"
                outlined
                required
                :rules="[required]"
                v-model="model.percentage"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="b-dialog--actions">
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            title="Salvar"
            :disabled="!valid"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { randomId, unmask } from '@/helpers/utils';
import { showMessage } from '@/helpers/messages';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    edit: {
      type: Boolean,
      required: true,
      default: false
    },
    selectItem: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    valid: true,
    model: {
      id: null,
      name: null,
      document: null,
      kinship: null,
      percentage: null
    }
  }),

  mounted() {
    if (this.edit) {
      this.model = Object.assign({}, this.selectItem);
    }
  },

  methods: {
    closeModal(item) {
      this.$emit('closeModal', item);
    },

    save() {
      if (!this.$refs.form.validate(true)) {
        return;
      } else if (this.model.percentage == 0) {
        showMessage(
          'error',
          'Não é possivel incluir percentual igual a 0 para o beneficiário'
        );
        return;
      } else if (this.model.percentage > 100) {
        showMessage(
          'error',
          'Não é possivel incluir percentual maior que 100 para o beneficiário'
        );
        return;
      } else {
        const params = Object.assign({}, this.model);
        params.document = unmask(params.document);

        if (!this.edit) {
          params.id = randomId();
        }

        this.closeModal(params);
      }
    }
  }
};
</script>
